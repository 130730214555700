@charset "utf-8";
@use "./settings" as variable;
@use "./tools/mixin" as mixin;

// calcを計算式のままコンパイルするための変数
$calcSp: 750;
$calcPc: 1200;
$calcXxl: 1440;
$calcPcMax: 1920;

/* =============================================================
    404 NotFound Page
============================================================= */

.error-contents {
  width: calc(630 * (100vw / #{$calcSp}));
  margin: 0 auto;
  padding-bottom: calc(130 * (100vw / #{$calcSp}));
  min-height: calc(100vh - (218 * (100vw / #{$calcSp})));
  @include mixin.responsive("md") {
    padding-bottom: calc(71 * (100vw / #{$calcPc}));
    width: calc(900 * (100vw / #{$calcPc}));
    height: auto;
    min-height: calc(100vh - (220 * (100vw / #{$calcPc})));
  }
  @include mixin.responsive("xl") {
    padding-bottom: 71px;
    width: 900px;
    min-height: calc(100vh - 220px);
  }
  &__ttl {
    margin-bottom: calc(60 * (100vw / #{$calcSp}));
    text-transform: uppercase;
    font-family: var(--font-en);
    font-size: calc(44 * (100vw / #{$calcSp}));
    line-height: 1.18;
    letter-spacing: 0.15em;
    text-align: center;
    color: var(--color-black);
    @include mixin.responsive("md") {
      margin-bottom: calc(90 * (100vw / #{$calcPc}));
      font-size: calc(36 * (100vw / #{$calcPc}));
      line-height: 1;
    }
    @include mixin.responsive("xl") {
      margin-bottom:90px;
      font-size: 36px;
      line-height: 1;
    }
  }
  &__txt {
    font-size: calc(24 * (100vw / #{$calcSp}));
    line-height: 2;
    letter-spacing: 0.15em;
    text-align: center;
    color: var(--color-black);
    @include mixin.responsive("md") {
      font-size: calc(14 * (100vw / #{$calcPc}));
    }
    @include mixin.responsive("xl") {
      font-size: 14px;
    }
    &--paragraph {
      margin-bottom: calc(140 * (100vw / #{$calcSp}));
      @include mixin.responsive("md") {
        margin-bottom: calc(60 * (100vw / #{$calcPc}));
      }
      @include mixin.responsive("xl") {
        margin-bottom: 60px;
      }
    }
  }
  .contents__btn--outer {
    width: calc(356 * (100vw / #{$calcSp}));
    height: calc(60 * (100vw / #{$calcSp}));
    margin: 0 auto calc(140 * (100vw / #{$calcSp}));
    @include mixin.responsive("md") {
      width: calc(200 * (100vw / #{$calcPc}));
      height: calc(40 * (100vw / #{$calcPc}));
      margin-bottom: calc(90 * (100vw / #{$calcPc}));
      transition: var(--transition-default);
    }
    @include mixin.responsive("xl") {
      width: 200px;
      height: 40px;
      margin-bottom: 90px;
    }
    .contents__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--color-black-dark);
      border-radius: 0;
      width: 100%;
      height: 100%;
      font-size: calc(24 * (100vw / #{$calcSp}));
      letter-spacing: 0.15em;
      line-height: 1;
      font-family: var(--font-en);
      color: var(--color-white);
      background-color: var(--color-black-dark);
      @include mixin.responsive("md") {
        transition: var(--transition-default);
        font-size: calc(14 * (100vw / #{$calcPc}));
      }
      @include mixin.responsive("xl") {
        font-size: 14px;
      }
      &:hover {
        @include mixin.responsive("md") {
          color: var(--color-black-dark);
          background-color: var(--color-white);
        }
      }
    }
  }
}
.online-store__nav--outer {
  @include mixin.responsive("md") {
    z-index: 9999;
  }
}


